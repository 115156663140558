import { add, format, formatRelative } from 'date-fns';

import enUS from 'date-fns/locale/en-US';

/**
 * Get the 12 hour format from a 24hours formatted hour and mins
 * @param {number} hour Hours between 0 and 23 (inclusive)
 * @param {number} mins Minutes between 0 and 59 (inclusive)
 * @return {string} The time in 12 hours format - HH:MM AM/PM
 */
export const getHours = (hour, mins) => {
  const time = hour > 12 ? hour % 12 : hour;
  const format = hour >= 12 ? ' PM' : ' AM';
  const minutes = mins >= 10 ? mins : `0${mins}`;
  return `${time}:${minutes}${format}`;
};

const formatDate = (date) =>
  typeof date === 'string' ? new Date(date) : date;
export const monthListLong = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

/**
 * Format a date into DD MMM YYYY
 * @param {date} dateString
 * @return {string} Date format in DD MMM YYYY
 */
export const formatTime = (dateString) => {
  const date = new Date(dateString);
  return `${date.getDate()} ${
    monthListLong[date.getMonth()]
  } ${date.getFullYear()}`;
};

/**
 *
 * @param {*} date - the first date (date in the past)
 * @returns '1y' || '1m' || '1d' || '1h' || '1m' || '1s'
 */
export function timeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval <= 0) return 'Just now';

  if (interval > 1) {
    return Math.floor(interval) + 'y';
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + 'mo';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    if (interval > 7) {
      const inWeeks = interval / 7;
      return Math.floor(inWeeks) + 'w';
    }

    return Math.floor(interval) + 'd';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + 'h';
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + 'm';
  }
  return 'Just now';
}

/**
 * Format a date into DD MMM YYYY HH:MM
 * @param {date} dateString
 * @return {string} Date format in DD MMM YYYY HH:MM
 */
export const formatDateTime = (dateString, withTime = true) => {
  const date = new Date(dateString);
  if (withTime) {
    return `${date.getDate()} ${getMonthName(
      date.getMonth()
    )} ${date.getFullYear()} ${getHours(
      date.getHours(),
      date.getMinutes()
    )}`;
  }
  return `${date.getDate()} ${getMonthName(
    date.getMonth()
  )} ${date.getFullYear()}`;
};

/**
 * Get the name of the month
 * @param {number} monthIndex Index of the month. 0 = January, 1 = February, ..., 11 = December
 * @return {string} Name of the month
 */
export const getMonthName = (monthIndex, short = false) => {
  const months = short
    ? [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
    : [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ];
  return months[monthIndex];
};

export const getDayName = (dayIndex, short = false) => {
  const days = short
    ? ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat']
    : [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];
  return days[dayIndex];
};

export const getDayRelativeToNow = (date) => {
  var formatRelativeLocale = {
    lastWeek: 'eee',
    yesterday: 'eee',
    today: "'Today'",
    tomorrow: "'Tomorrow'",
    nextWeek: 'eee',
    other: 'eee'
  };

  return formatRelative(date, new Date(), {
    locale: {
      ...enUS,
      formatRelative: (token) => formatRelativeLocale[token]
    }
  });
};

/**
 * Convert date into client's browser timezone
 * @param {date} dateString Date to be converted
 * @return {date} A date converted to client's browser timezone.
 */
export const convertToBrowserTimeZone = (dateString) => {
  return new Date(dateString).toLocaleString('en-US', {
    timeZone: getTimezoneId()
  });
};

/**
 * Get the timezone offset in GMT
 * @param {date} dateObject The date to get the timezone offset from
 * @return {string} The timezone offset in GMT
 */
export const getTimezoneOffset = (dateObject) => {
  let offset = dateObject.getTimezoneOffset();
  const sign = offset < 0 ? '+' : '-';
  offset = Math.abs(offset);
  return sign + offset / 60;
};

/**
 * Create date as UTC
 * @param {number} year The full year of the date in UTC
 * @param {number} month The month of the date in UTC. Note that month January is 1 and month December is 12 (different from Javascript default Date implementation)
 * @param {number} day The day of the month in UTC.
 * @param {number} hour The hour of the day in UTC.
 * @param {number} minute The minute in UTC.
 * @param {number} second The second in UTC.
 *
 * @return {date} A date with the UTC details
 */
export const createDateAsUTC = (
  year,
  month,
  day,
  hour,
  minute,
  second
) => {
  const date = new Date(
    Date.UTC(year, parseInt(month) - 1, day, hour, minute, second)
  );
  return date;
};

/**
 * Convert date in ISO format to an object with the year, month, date, hours, minutes, and seconds.
 * @param {date} dateTimeString Date in ISO format. Must be in the format of yyyy-mm-ddThh:mm:ss e.g. 2020-07-23T04:30:15
 * @return {object} A custom date object
 */
export const createDateTimeObject = (dateTimeString) => {
  const dateTimeToken = dateTimeString.split('T');
  const dateString = dateTimeToken[0];
  const timeString = dateTimeToken[1];

  const dateToken = dateString.split('-');
  const timeToken = timeString.split(':');

  return {
    year: dateToken[0],
    month: dateToken[1],
    date: dateToken[2],
    hours: timeToken[0],
    minutes: timeToken[1],
    seconds: timeToken[2]
  };
};

/**
 *
 * @param {"2020-09-23T04:30:00"} dateTimeString
 * @param {"13"} customHoursString is the hours in 24-hour format
 * @param {"45"} customMinutesString is the minutes
 * @param {"Moment timezone"} timezone
 */
export const convertFromStringToUTCDateTime = (
  dateTimeString,
  customHourString,
  customMinutesString
) => {
  const dateTimeObject = createDateTimeObject(dateTimeString);
  const startDateUTC = createDateAsUTC(
    dateTimeObject.year,
    dateTimeObject.month,
    dateTimeObject.date,
    dateTimeObject.hours,
    dateTimeObject.minutes,
    dateTimeObject.seconds
  );
  startDateUTC.setUTCHours(customHourString);
  startDateUTC.setUTCMinutes(customMinutesString);

  return startDateUTC;
};

/**
 * Convert time from 12 hour format to 24 hour format.
 * @param {string} time12h Time in 12hour format
 * @param {string} hourMinuteSeparator The string that separates the hours from minutes.
 * @param {string} amPmSeparator The string that separates the modifier (AM/PM) from the time
 * @return {string} The time in 24 hour format
 */
export const convertTime12To24 = (
  time12h,
  hourMinuteSeparator,
  amPmSeparator
) => {
  let time;
  let modifier;
  let hours;
  let minutes;

  // IF format is 1.03AM
  const trimmedTime12h = time12h.trim().toUpperCase();
  if (!amPmSeparator && trimmedTime12h.match(/AM|PM/)) {
    const { index } = trimmedTime12h.match(/AM|PM/);
    time = trimmedTime12h.slice(0, index);
    modifier = trimmedTime12h.slice(index);
  } else {
    // IF format is 1.03 PM
    [time, modifier] = trimmedTime12h.split(' ');
  }

  [hours, minutes] = time.split(hourMinuteSeparator);

  hours = hours.trim();
  minutes = minutes.trim();
  if (hours === '12') {
    hours = '00';
  }

  if (modifier.toUpperCase() === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
};

/**
 * Convert time from 24 hour format to 12 hour format.
 * @param {string} time24h Time in 24hour format
 * @param {string} currentHourMinuteSeparator The string that separates the hours from minutes.
 * @param {string} newHourMinuteSeparator The string that separates the hours from minutes
 * @return {string} The time in 12 hour format
 */
export const convertTime24To12 = (
  time24h,
  currentHourMinuteSeparator,
  newHourMinuteSeparator
) => {
  const [hours, minutes] = time24h.split(currentHourMinuteSeparator);

  let modifier;
  let newHours;
  // Validate hours
  if (parseInt(hours) === 0) newHours = 12;
  else if (parseInt(hours) >= 1 && parseInt(hours) <= 12)
    newHours = parseInt(hours);
  else if (parseInt(hours) >= 13 && parseInt(hours) <= 23)
    newHours = parseInt(hours) - 12;
  else
    throw new Error(
      'Hours is out of range. Hours should be between 0 to 23 (inclusive).'
    );

  // Validate minutes
  if (parseInt(minutes) < 0 || parseInt(minutes) >= 60)
    throw new Error(
      'Minutes is out of range. Minutes should be between 0 to 59 (inclusive).'
    );

  // Identify identifier for PM or AM
  if (parseInt(hours) >= 12 && parseInt(hours) <= 23) {
    modifier = 'PM';
  } else if (parseInt(hours) >= 0 && parseInt(hours) <= 11)
    modifier = 'AM';
  else throw new Error('Invalid 12-hour time format');

  const separator = newHourMinuteSeparator || '.';

  return `${newHours}${separator}${minutes} ${modifier}`;
};

/**
 * Get the time difference between a 24 hour start time and 24 hour end time
 * @param {string} timing The start time to end time in 24 hour format
 * @return {string} The custom time diff object that contain the hours and minutes difference
 */
export const getTimeDiff = (timing) => {
  // example 07:00 - 09:30
  // example 23:00 - 01:30
  // example 7:30 - 09:15

  const [startTime, endTime] = timing.split('-');
  let [startHour, startMin] = startTime.split(':');
  let [endHour, endMin] = endTime.split(':');

  startHour = parseInt(startHour);
  startMin = parseInt(startMin);

  endHour = parseInt(endHour);
  endMin = parseInt(endMin);

  let diffHour;
  let diffMin = 0;
  if (startHour <= endHour) diffHour = endHour - startHour;
  else diffHour = endHour - startHour + 24;

  if (startMin <= endMin) diffMin = endMin - startMin;
  else {
    diffMin = endMin - startMin + 60;
    diffHour -= 1;
  }

  return {
    diffHours: diffHour,
    diffMinutes: diffMin
  };
};

/**
 * Get the upcoming date of the selected day.
 * For example:
 *      - If today is Tuesday (21-July-2020) and you want to get the upcoming Thursday, you will get 30-July-2020.
 *      - If today is Friday (24-July-2020) and you want to get the upcoming Monday, you will get 27-July-2020.
 * @param {date} startDate Start date to be used as a base date and time
 * @param {number} targetDay Day of the week for the upcoming date you want. Sunday = 0, Monday = 1, ... Saturday = 6.
 * @param {number} targetStartUTCHours Hours in UTC 24 hour format for the upcoming date you want.
 * @param {number} targetStartUTCMinutes  Minutes in UTC 24 hour format for the upcoming date you want.
 * @return {date} The upcoming date based on the day you want.
 */
export const getDateOfNextDay = (
  startDate,
  targetDay,
  targetStartUTCHours,
  targetStartUTCMinutes
) => {
  const offset = targetDay - startDate.getUTCDay();
  const nextWeek = startDate;

  if (offset > 0) nextWeek.setUTCDate(startDate.getUTCDate() + offset);
  else if (offset < 0)
    nextWeek.setUTCDate(startDate.getUTCDate() + offset + 7);
  else if (
    startDate.getUTCHours() > targetStartUTCHours ||
    (startDate.getUTCHours() === targetStartUTCHours &&
      startDate.getUTCMinutes() > targetStartUTCMinutes)
  )
    nextWeek.setUTCDate(startDate.getUTCDate() + offset + 7);

  nextWeek.setUTCHours(targetStartUTCHours);
  nextWeek.setUTCMinutes(targetStartUTCMinutes);

  return nextWeek;
};

export const getDayOfWeek = (dayString) => {
  const daysOfWeek = {
    0: 'Sun',
    1: 'Mon',
    2: 'Tues',
    3: 'Wednes',
    4: 'Thurs',
    5: 'Fri',
    6: 'Satur',
    7: 'Sun'
  };

  return daysOfWeek[dayString.getDay()];
};

export const getFormattedDateRange = (
  start,
  end,
  shortFormat,
  longFormat
) => {
  const startDateFormatted = new Date(start).toLocaleString(
    'en-US',
    shortFormat
  );
  const endDateFormatted = new Date(end).toLocaleString(
    'en-US',
    longFormat
  );

  const dateToRender =
    startDateFormatted === endDateFormatted.split(',')[0]
      ? endDateFormatted
      : `${startDateFormatted} - ${endDateFormatted}`;

  return dateToRender;
};

export const formatAMPM = (date, cap = false) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm;
  if (cap) {
    ampm = hours >= 12 ? 'PM' : 'AM';
  } else {
    ampm = hours >= 12 ? 'pm' : 'am';
  }
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return `${hours}:${minutes} ${ampm}`;
};

/**
 * @param {*} startDate
 * @param {*} endDate
 * @param {string} courseTiming
 */
export const getLocalCourseTime = (startDate, endDate) => {
  let startDateTimeLocal = new Date(startDate);
  let endDateTimeLocal = new Date(endDate);
  return {
    startDateTimeLocal,
    endDateTimeLocal,
    formattedStartDateTimeLocal: formatDateTime(startDateTimeLocal, false),
    formattedEndDateTimeLocal: formatDateTime(endDateTimeLocal, false),
    lessonStartTime: formatAMPM(startDateTimeLocal, true),
    lessonEndTime: formatAMPM(endDateTimeLocal, true),
    firstLessonDay: getDayName(startDateTimeLocal.getDay()),
    secondLessonDay: getDayName(endDateTimeLocal.getDay())
  };
};

export const getTimezoneId = () => {
  //TODO: We should have another polyfill to get the timezone id for old browsers' support
  const dateTimeFormat = new Intl.DateTimeFormat();
  return dateTimeFormat.resolvedOptions().timeZone;
};

export const longDateFormat = 'EEEE, d MMMM yyyy';

export const convertShortDayDateFormat = (date = '') => {
  try {
    if (!date) return '';
    return format(new Date(date), 'EEEE, d LLL');
  } catch (err) {
    return '';
  }
};

export const courseBannerDateFormat = longDateFormat;

export const formatCourseDateForBanner = (courseDate) => {
  return courseDate
    ? format(new Date(courseDate), courseBannerDateFormat)
    : '';
};

export const formatCalendarEndDateTime = (startDate, endDate) => {
  if (startDate && endDate) {
    const formatStartDate = new Date(startDate);
    const formatEndDate = new Date(endDate);

    const startDateHours = formatStartDate.getHours();
    const endDateHours = formatEndDate.getHours();
    const endDateMinutes = formatEndDate.getMinutes();

    formatStartDate.setHours(endDateHours);
    formatStartDate.setMinutes(endDateMinutes);

    if (endDateHours < startDateHours) {
      formatStartDate.setDate(formatStartDate.getDate() + 1);
    }

    return formatStartDate;
  }
  return new Date();
};

export const convertMsToTime = (msDuration) => {
  const h = Math.floor(msDuration / 1000 / 60 / 60);
  const m = Math.floor((msDuration / 1000 / 60 / 60 - h) * 60);
  const s = Math.floor(((msDuration / 1000 / 60 / 60 - h) * 60 - m) * 60);

  return {
    hrs: h,
    mins: m,
    secs: s
  };
};

export function dateDiff(first, second) {
  const hourDifference = Math.abs(second - first) / 36e5;
  return {
    days: Math.floor(hourDifference / 24),
    hours: Math.floor(hourDifference % 24)
  };
}
// TODO write test for this.
export const getLocalCurrentAndRecurringMonthlyDeadline = (day) => {
  const currentDate = new Date();
  const localDay = parseInt(format(currentDate, 'd'));

  const targetDate =
    localDay < day ? currentDate : add(currentDate, { months: 1 });

  const localMonth = format(targetDate, 'L') - 1;
  const localYear = format(targetDate, 'Y');

  const localDeadline = new Date(localYear, localMonth, day);
  const localDeadlineString = format(localDeadline, 'PPPP');
  const currentLocalDateString = format(currentDate, 'PPPP');

  const isDeadlineDay = localDay === day;

  return {
    currentLocalDateString,
    localDay,
    localDeadline,
    localDeadlineString,
    isDeadlineDay
  };
};

/*
returning date in format similar to 22 Feb 2022
*/

export const getShortMonthDate = (date = '') => {
  try {
    if (!date) return '';
    return format(new Date(date), 'd LLL y');
  } catch (err) {
    return '';
  }
};

/**
 * Check if given dateB is "numDays" days away from dateA.
 * @param {date} dateA
 * @param {date} dateB
 * @param {number} numDays Number of Days to add to Today.
 * @return {boolean} True is given date is numDays away from Today
 */
export const isDateNDaysBeyond = (dateA, dateB, numDays) => {
  try {
    const currDate = new Date(dateA);
    const targetDate = new Date(dateB);
    const nDaysLaterDate = add(currDate, { days: numDays });

    return targetDate > nDaysLaterDate;
  } catch (e) {
    return false;
  }
};

/**
 * Given a date in UTC, it will return the date in local timezone.
 * @param {string} dateString
 * @returns {date}
 */
export const getDateFromUTCString = (dateString) => {
  if (typeof dateString === 'string') {
    dateString.indexOf('Z') === -1 ? (dateString += 'Z') : dateString;
    return new Date(dateString);
  }
  return dateString;
};

/**
 * @param {string} dateString
 * @returns {bool}
 */
export const isDateInPast = (dateString) => {
  const paramDate = new Date(dateString);
  const now = new Date();

  return paramDate < now;
};

export const getDateItemsObject = (dateInput) => {
  const formattedDate = formatDate(dateInput);
  const month = new Intl.DateTimeFormat('en-US', {
    month: 'long'
  }).format(formattedDate);
  const date = formattedDate.getDate();

  // get time from date
  const time = format(formattedDate, 'h:mm a');

  let day = new Intl.DateTimeFormat('en-US', {
    weekday: 'long'
  }).format(formattedDate);

  const presentDate = new Date();

  const presentDateDay = presentDate.getDate();
  const isCurrentMonth =
    presentDate.getMonth() === formattedDate.getMonth();
  const isCurrentYear =
    presentDate.getFullYear() === formattedDate.getFullYear();

  const isActivePeriod = isCurrentMonth && isCurrentYear;
  day =
    presentDateDay === date && isActivePeriod
      ? 'Today'
      : (presentDateDay - date) * 1 === 1 && isActivePeriod
      ? 'Yesterday'
      : day;

  return {
    month,
    date,
    day,
    time
  };
};

const shortDayToLong = {
  Mon: 'Monday',
  Tue: 'Tuesday',
  Wed: 'Wednesday',
  Thu: 'Thursday',
  Fri: 'Friday',
  Sat: 'Saturday'
};
const shortMonthToLong = {
  Jan: 'January',
  Feb: 'February',
  Mar: 'March',
  Apr: 'April',
  May: 'May',
  Jun: 'June',
  Jul: 'July',
  Aug: 'August',
  Sep: 'September',
  Oct: 'October',
  Nov: 'November',
  Dec: 'December'
};

export const getLongDay = (shortDay) => {
  return shortDayToLong[shortDay];
};
export const getLongMonth = (shortMonth) => {
  return shortMonthToLong[shortMonth];
};

export function formatSecondsToBrokenDownTime(totalSeconds) {
  let hours = Math.floor(totalSeconds / 3600);
  let minutes = Math.floor((totalSeconds % 3600) / 60);
  let seconds = totalSeconds % 60;
  let formattedDuration = '';
  formattedDuration = hours > 0 ? Math.trunc(hours) + 'h ' : '';
  formattedDuration += minutes > 0 ? Math.trunc(minutes) + 'm ' : '';
  formattedDuration += seconds > 0 ? Math.trunc(seconds) + 's' : '';

  return formattedDuration;
}

export const addToDate = (date, { days = 0, months = 0, years = 0 }) => {
  console.log('date', date, { days, months, years });
  return add(date, { days, months, years });
};

export const getThisMonth = () => {
  const date = new Date();
  const month = date.toLocaleString('default', { month: 'long' });
  return month;
};

// return same date next month in format 31 July 2023
export const getSameDayinXMonths = (date, months = 1) => {
  const nextMonth = add(date, { months });
  return getShortMonthDate(nextMonth);
};
